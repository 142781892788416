<template>
  <nav class="d-none navbar navbar-fluid d-lg-flex justify-content-around navbar-expand-md fixed-top" :class="{'navbar-dark navbar-ontop': currentView==='/', 'navbar-light bg-light': currentView!=='/'}">
    <div class="w-100">
      <button @click="toggleMobileNav" class="transition navbar-toggler navbar-toggler-right border-0" type="button" data-toggle="collapse"
              data-target="#navbar3">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div :class="{show: mobileOpen}" class="collapse transition navbar-collapse w-100 text-center justify-content-around" id="navbar3">
        <ul class="navbar-nav col-md-4 d-flex flex-row justify-content-end align-items-center">
          <li class="nav-item">
            <a class="nav-link" @click="redirectTo('#')" data-placement="bottom" data-toggle="tooltip"
               title="Follow us on Twitter">
              <i class="fa fa-fw fa-twitter"></i>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" @click="redirectTo('#')" data-placement="bottom" data-toggle="tooltip"
               title="Like us on Facebook">
              <i class="fa fa-fw fa-facebook-official"></i>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" @click="redirectTo('#')" data-placement="bottom" data-toggle="tooltip"
               title="Follow us on Instagram">
              <i class="fa fa-fw fa-instagram"></i>
            </a>
          </li>
          <li class="nav-item px-2 border-top border-primary"><a class="nav-link" @click="redirectTo('')" :class="{active: currentView==='/'}">Home</a></li>
          <li class="nav-item px-2 border-top border-primary"><a class="nav-link" @click="redirectTo('photos')" :class="{active: currentView==='/photos'}">Photos</a></li>
          <li class="nav-item px-2 border-top border-primary"><a class="nav-link" @click="redirectTo('films')" :class="{active: currentView==='/films'}">Films</a></li>
        </ul>
        <ul class="navbar-nav col-md-2 text-center d-flex flex-row justify-content-center">
          <li class="nav-item mx-2 px-xl-5"><a class="nav-link navbar-brand mr-0 text-primary" @click="redirectTo('')">
            <img class="img-fluid" :class="{invert: !this.transparent}" :src="'./assets/img/logo.png'" />

          </a></li>
        </ul>
        <ul class="navbar-nav col-md-4">
          <li class="nav-item px-2 border-top border-primary"><a class="nav-link" @click="redirectTo('clientaccess')" :class="{active: currentView==='/clientaccess'}">Accès Client</a></li>
          <li class="nav-item px-2 border-top border-primary"><a class="nav-link" @click="redirectTo('about')" :class="{active: currentView==='/about'}">About</a></li>
          <li class="nav-item px-2 border-top border-primary"><a class="nav-link" @click="redirectTo('contact')" :class="{active: currentView==='/contact'}">Contacts</a></li>
          <!--<li class="nav-item px-2"><a class="nav-link" @click="redirectTo('#')">-->
            <!--<i class="fa fa-fw fa-search"></i>-->
          <!--</a></li>-->
        </ul>
      </div>
    </div>
  </nav>
  <nav class="transition mobile-nav d-flex navbar navbar-fluid d-lg-none justify-content-around navbar-expand-md fixed-top pointer-events-none" :class="{'navbar-transparent navbar-dark': !mobileOpen && this.transparent, 'bg-light': mobileOpen, 'navbar-light': mobileOpen || !this.transparent}">
    <div class="w-100">

      <div class="d-flex flex-direction-row justify-content-between">
        <button @click="toggleMobileNav" class="d-block d-lg-none transition navbar-toggler navbar-toggler-right border-0 pointer-events-all" type="button" data-toggle="collapse"

                data-target="#navbar3">
          <span class="navbar-toggler-icon"></span>
        </button>
        <a class="navbar-brand" @click="redirectTo('')">
          <img class="img-fluid transition" :class="{invert: mobileOpen || !this.transparent}" :src="'./assets/img/logo.png'" />
        </a>
      </div>
      <div :class="{'active opacity-100 pointer-events-all': mobileOpen}" class="transition opacity-0 navbar-collapse w-100 text-center justify-content-around" id="navbar3">
        <ul class="navbar-nav ">
          <li>
            <ul class="navbar-nav col-md-4 d-flex flex-row justify-content-md-start justify-content-center align-items-center">
              <li class="nav-item">
                <a class="nav-link" @click="redirectTo('#')" data-placement="bottom" data-toggle="tooltip"
                   title="Follow us on Twitter">
                  <i class="fa fa-2x fa-fw fa-twitter"></i>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" @click="redirectTo('#')" data-placement="bottom" data-toggle="tooltip"
                   title="Like us on Facebook">
                  <i class="fa fa-2x fa-fw fa-facebook-official"></i>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" @click="redirectTo('#')" data-placement="bottom" data-toggle="tooltip"
                   title="Follow us on Instagram">
                  <i class="fa fa-2x fa-fw fa-instagram"></i>
                </a>
              </li>
            </ul>
          </li>
          <li class="nav-item px-2 border-top border-primary"><a class="nav-link" @click="redirectTo('')" :class="{active: currentView==='/'}">Home</a></li>
          <li class="nav-item px-2 border-top border-primary"><a class="nav-link" @click="redirectTo('photos')" :class="{active: currentView==='/photos'}">Photos</a></li>
          <li class="nav-item px-2 border-top border-primary"><a class="nav-link" @click="redirectTo('films')" :class="{active: currentView==='/films'}">Films</a></li>
          <li class="nav-item px-2 border-top border-primary"><a class="nav-link" @click="redirectTo('clientaccess')" :class="{active: currentView==='/clientaccess'}">Accès Client</a></li>
          <li class="nav-item px-2 border-top border-primary"><a class="nav-link" @click="redirectTo('about')" :class="{active: currentView==='/about'}">About</a></li>
          <li class="nav-item px-2 border-top border-primary"><a class="nav-link" @click="redirectTo('contact')" :class="{active: currentView==='/contact'}">Contacts</a></li>
          <!--<li class="nav-item px-2"><a class="nav-link" @click="redirectTo('#')">-->
            <!--<i class="fa fa-fw fa-search fa-2x"></i>-->
          <!--</a></li>-->
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'TopMenu',
  props:['transparent'],
  data() {
    return {
      currentPath: window.location.hash,
      mobileOpen: false
    }
  },
  methods: {
    toggleMobileNav(){
      console.log(this.mobileOpen);
      this.mobileOpen = !this.mobileOpen
    },
    redirectTo(page){
      this.$router.push("/" + page);
    }
  },
  computed: {
    currentView() {
      return this.$route.path || '/'
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .navbar-brand>img.invert{
    filter: invert(0.5);
  }
  .navbar-brand:hover>img.invert{
    filter: invert(0.7);
  }
  .navbar-brand:hover img{
    filter: invert(0.1);
  }
  .mobile-nav .navbar-collapse {
    margin-left: -100%;
  }

  .mobile-nav .navbar-collapse.active {
    margin-left: 0;
  }
  .navbar-toggler:focus{
    box-shadow: none;
  }

  .mobile-nav .navbar-brand{
    width: 54px;
  }
  .nav-item:hover{
    cursor: pointer;
  }
  .pointer-events-none{
    pointer-events: none;
  }


  .pointer-events-all{
    pointer-events: all;
  }
</style>

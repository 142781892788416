// src/router.js

import {createRouter, createWebHistory} from 'vue-router';
import {BACKEND_URL} from "./main";

const appBase = "";

const routes = [{
    component: () => import("./components/HomePage"),
    name: "HomePage",
    path: appBase + "/"
}, {
    component: () => import("./components/About"),
    name: "About",
    path: appBase + "/about"
}, {
    component: () => import("./components/Films"),
    name: "Films",
    path: appBase + "/films"
}, {
    component: () => import("./components/Contact"),
    name: "Contact",
    path: appBase + "/contact"
}, {
    component: () => import("./components/ClientAccess"),
    name: "ClientAccess",
    path: appBase + "/access"
}, {
    component: () => import("./components/Cours"),
    name: "Cours",
    path: appBase + "/course"
}, {
    component: () => import("./components/Gifs"),
    name: "Gifs",
    path: appBase + "/gifs"
}, {
    component: () => import("./components/Access"),
    name: "Memories",
    path: appBase + "/clientaccess"
}, {
    component: () => import("./components/Portfolio"),
    name: "Portfolio",
    path: appBase + "/portfolio"
}, {
    component: () => import("./components/Albums"),
    name: "Albums",
    path: appBase + "/photos"
}, {
    component: () => import("./components/Thanks"),
    name: "Thanks",
    path: appBase + "/thanks"
}, {
    component: () => import("./components/admin/about"),
    name: "AboutAdmin",
    path: appBase + "/admin/about",
    meta: {
        requiresAuth: true
    }
}, {
    component: () => import("./components/admin/albums"),
    name: "AlbumsAdmin",
    path: appBase + "/admin/albums",
    meta: {
        requiresAuth: true
    }
}, {
    component: () => import("./components/admin/Access"),
    name: "AccessAdmin",
    path: appBase + "/admin/access",
    meta: {
        requiresAuth: true
    }
}, {
    component: () => import("./components/admin/thanks"),
    name: "ThanksAdmin",
    path: appBase + "/admin/thanks",
    meta: {
        requiresAuth: true
    }
}, {
    component: () => import("./components/admin/Films"),
    name: "FilmsAdmin",
    path: appBase + "/admin/videos",
    meta: {
        requiresAuth: true
    }
}, {
    component: () => import("./components/admin/AddAlbums"),
    name: "AddAlbumsAdmin",
    path: appBase + "/admin/albums/add",
    meta: {
        requiresAuth: true
    }
}, {
    component: () => import("./components/admin/AddAccess"),
    name: "AddAccessAdmin",
    path: appBase + "/admin/access/add",
    meta: {
        requiresAuth: true
    }
}, {
    component: () => import("./components/admin/AddThanks"),
    name: "AddThanksAdmin",
    path: appBase + "/admin/thanks/add",
    meta: {
        requiresAuth: true
    }
}, {
    component: () => import("./components/admin/AddFilm"),
    name: "AddFilmAdmin",
    path: appBase + "/admin/videos/add",
    meta: {
        requiresAuth: true
    }
}, {
    component: () => import("./components/Album"),
    name: "Album",
    path: appBase + "/album"
}, {
    component: () => import("./components/Login"),
    name: "Login",
    path: appBase + "/log-in"
}, {
    component: () => import("./components/admin/Home"),
    name: "AdminHome",
    path: appBase + "/admin",
    meta: {
        requiresAuth: true
    }
}];

const router = createRouter({
    history: createWebHistory("/bill-aiss/"),
    routes
});

function redirect(to, next) {
    const shouldRedirect = to.query && to.query.page;// Your logic to determine if redirection is needed
    if (shouldRedirect) {
        const page = to.query.page === 'home' ? '' : to.query.page;
        next(appBase + '/' + page); // Replace '/new-path' with the desired redirection path
    } else {
        next(); // Continue with the navigation
    }
}

router.beforeEach((to, from, next) => {
    // Check the URL and redirect if necessary
    let baseUrl = BACKEND_URL;

    fetch(baseUrl + '/check_login.php')
        .then(response => response.json())
        .then(data => {
            const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

            if (requiresAuth && !data.loggedIn) {
                next(appBase + '/log-in');
            } else {
                redirect(to, next);
            }
        })
        .catch(error => {
            console.error('Error checking login status:', error);
            // An error occurred, redirect to the connection page or display an error message
//            next('/log-in');
        });

});

console.log("router set");

export default router;

<template>
  <top-menu ></top-menu>
  <router-view></router-view>
</template>

<script>
  import TopMenu from "./components/TopMenu";
  // import RouterComponent from "./components/Router";
export default {
  components: {TopMenu},
  name: 'App',
}
</script>

<style>
  .page-title{
    font-family: 'Tangerine', cursive;
    font-style: italic;
    font-size: 40pt;
    opacity: 30%;
  }
</style>
